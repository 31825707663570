import { IStyle } from '@fluentui/react';
import { WordBreakType } from '@tokens';
import { RICH_TEXT_CONTAINER_PADDING } from './RichText.constants';

export interface IRichTextStyles {
    container: (color?: string, maxLine?: number, wordBreak?: WordBreakType) => IStyle[];
}

export const RichTextStyles: IRichTextStyles = {
    container: (color, maxLine, wordBreak) => [
        {
            color,
            overflow: 'hidden',
            padding: RICH_TEXT_CONTAINER_PADDING,
            wordBreak: maxLine ? wordBreak : undefined,
            whiteSpace: 'pre-wrap',
        },
        {
            'display': '-webkit-box',
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': maxLine?.toString(),
        },
        {
            '> p': {
                margin: 0,
            },
        },
    ],
};
