import React, { useMemo } from 'react';
import * as Fluent from '@fluentui/react';
import { Stack, Text } from '@fluentui/react';
import { useTheme } from '@theme';
import { Icon, IconSize } from '@tokens';
import { RichText } from '../rich-text';
import * as Constants from './Button.constants';
import { ButtonStyles as styles, ButtonTokens as tokens } from './Button.styles';
import { ButtonSize, IButtonProps } from './Button.types';

/**
 * A button means an operation (or a series of operations). Clicking a button will trigger corresponding business logic.
 */
export const Button: React.FC<IButtonProps> = (props) => {
    const { text, html, menuProps, onClick, disabled = false, leftIconProps, rightIconProps, size = ButtonSize.Medium, dataTestId } = props;

    const mozzaikTheme = useTheme();

    const iconSize = useMemo(() => {
        switch (size) {
            case ButtonSize.Small:
            case 'small':
                return IconSize.SIZE_12;
            case ButtonSize.Large:
            case 'large':
                return IconSize.SIZE_20;
            case ButtonSize.Medium:
            case 'medium':
            default:
                return IconSize.SIZE_16;
        }
    }, [size]);

    const contextualMenuProps = useMemo(() => {
        if (!menuProps) {
            return undefined;
        }

        const { directionalHint, items } = menuProps;

        return {
            directionalHint,
            gapSpace: Constants.MENU_GAP_FROM_BUTTON,
            items: items.map((item) => ({ ...item, itemProps: { styles: styles.menuItem(item, mozzaikTheme) } } as Fluent.IContextualMenuItem)),
            styles: styles.menu,
        } as Fluent.IContextualMenuProps;
    }, [menuProps]);

    return (
        <Fluent.DefaultButton
            menuProps={contextualMenuProps}
            onClick={onClick}
            disabled={disabled}
            styles={styles.button(props, mozzaikTheme)}
            data-testid={dataTestId}>
            <Stack horizontal verticalAlign="center" tokens={tokens.buttonContent} styles={styles.buttonTextContainer}>
                {leftIconProps && (
                    <Stack>
                        <Icon {...leftIconProps} size={iconSize} />
                    </Stack>
                )}
                {html ? <RichText html={html} /> : <Text styles={styles.label}>{text}</Text>}
                {rightIconProps && <Icon {...rightIconProps} size={iconSize} />}
            </Stack>
        </Fluent.DefaultButton>
    );
};
