import React from 'react';
import { Button } from '@atoms';
import * as Fluent from '@fluentui/react';
import { useTheme } from '@theme';
import { ModalFooterStyles as styles, ModalFooterTokens as tokens } from './ModalFooter.styles';
import { IModalFooterProps } from './ModalFooter.types';

export const ModalFooter: React.FC<IModalFooterProps> = (props) => {
    const { primaryButtonProps, secondaryButtonProps, buttonsAlignment = 'end' } = props;
    const theme = useTheme();

    return (
        <Fluent.Stack horizontal horizontalAlign="end" tokens={tokens.buttons} styles={styles.container(theme)} verticalAlign={buttonsAlignment}>
            <Button {...primaryButtonProps} />
            <Button {...secondaryButtonProps} />
        </Fluent.Stack>
    );
};
