import { PersonaSize } from '@atoms';
import { MaxDisplayablePersonas } from './PersonasGroup.types';

export const PERSONA_CONTAINER_GAP = 4;

export const PERSONA_MARGIN_LEFT = -8;

export const personaSizeToNumber = new Map<PersonaSize, number>([
    [PersonaSize.Size16, 16],
    [PersonaSize.Size24, 24],
    [PersonaSize.Size28, 28],
    [PersonaSize.Size32, 32],
    [PersonaSize.Size40, 40],
    [PersonaSize.Size48, 48],
    [PersonaSize.Size72, 72],
    [PersonaSize.Size100, 100],
    [PersonaSize.Size120, 120],
]);

export const MaxDisplayablePersonasToNumber = new Map<MaxDisplayablePersonas, number>([
    ['2Personas', 2],
    ['3Personas', 3],
    ['4Personas', 4],
    ['5Personas', 5],
    ['6Personas', 6],
    ['7Personas', 7],
]);
