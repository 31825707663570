import * as Fluent from '@fluentui/react';
import { IPalette, ISemanticColors, Theme } from './ThemeProvider.types';

export function transformToMozzaikTheme(originalTheme: Fluent.Theme): Theme {
    const { palette } = originalTheme;
    const customizeTheme: Partial<Theme> = {
        palette: {
            red: '#af3036',
            success: '#5FD25533',
            error: '#FF3A4B33',
            successDark: '#107C10',
            errorDark: '#E81123',
        } as IPalette,
    };
    customizeTheme.semanticColors = {
        /* ActionBar */
        actionBarBackgroundStandard: palette.white,
        actionBarBackgroundPrimary: palette.themePrimary,

        /* callout */
        calloutBackgroundColor: palette.white,

        /* common */
        bodyTextHovered: palette.neutralPrimary,
        disabledBackground: palette.neutralLighter,
        disabledText: palette.neutralTertiaryAlt,

        /* buttons */
        buttonText: palette.themePrimary,
        buttonBackground: palette.white,
        buttonBorder: palette.themePrimary,
        buttonBackgroundHovered: palette.white,
        buttonBorderHovered: palette.themeTertiary,
        buttonTextHovered: palette.themeTertiary,
        buttonTextPressed: palette.themeDark,
        buttonBackgroundPressed: palette.white,
        buttonBorderPressed: palette.themeDark,
        buttonTextDisabled: palette.neutralTertiaryAlt,
        buttonBorderDisabled: palette.neutralTertiaryAlt,
        defaultButtonLabelMenu: palette.neutralPrimary,
        inactiveButtonBackground: palette.neutralLight,
        inactiveButtonBackgroundHovered: palette.neutralQuaternaryAlt,
        inactiveButtonBackgroundPressed: palette.neutralTertiaryAlt,
        inactiveButtonText: palette.neutralPrimary,
        neutralButtonBorder: palette.neutralTertiary,
        neutralButtonText: palette.neutralPrimary,
        neutralButtonTextHovered: palette.neutralTertiary,
        neutralIconButtonBorderFocused: palette.neutralSecondary,
        neutralIconButtonTextHovered: palette.neutralTertiary,
        neutralIconButtonTextPressed: palette.neutralPrimary,
        neutralPrimaryButtonBackground: palette.neutralLighter,
        neutralPrimaryIconButtonBackgroundHovered: palette.neutralQuaternaryAlt,
        neutralPrimaryIconButtonBackgroundPressed: palette.neutralTertiaryAlt,
        neutralPrimaryIconButtonBorderFocused: palette.neutralQuaternaryAlt,
        neutralPrimaryIconButtonTextHovered: palette.neutralPrimary,
        neutralTertiaryIconButtonBackgroundHovered: palette.neutralLighter,
        neutralTertiaryIconButtonBorderFocused: palette.neutralSecondary,
        neutralTertiaryIconButtonTextHovered: palette.neutralSecondary,
        neutralTertiaryIconButtonBackgroundPressed: palette.neutralLight,
        primaryButtonBackgroundHovered: palette.themeTertiary,
        tertiaryIconButtonBackgroundHovered: palette.themeLighterAlt,
        tertiaryIconButtonBackgroundPressed: palette.themeLighter,
        ghostTertiaryLightButtonHovered: palette.neutralLighter,
        ghostTertiaryLightButtonPressed: palette.neutralQuaternary,

        /* card */
        cardDefaultBackground: palette.white,
        cardDefaultBorder: palette.neutralLighter,
        cardDefaultHoveredBackground: palette.white,
        cardDefaultSelectedBackground: palette.themeLighterAlt,
        cardDefaultSelectedBorderColor: palette.themePrimary,

        /* carousel-item */
        carouselItemDarkColor: palette.black,
        carouselItemDarkColorActive: palette.neutralPrimary,

        /* checkbox */
        checkboxBackgroundDisabled: palette.neutralLighter,
        checkboxBorderDisabled: palette.neutralTertiaryAlt,
        checkboxBorderChecked: palette.themePrimary,
        checkboxBackgroundChecked: palette.themePrimary,
        checkboxBackground: palette.white,
        checkboxBorder: palette.neutralTertiary,
        checkboxTextDisabled: palette.neutralTertiaryAlt,
        checkmarkHovered: palette.white,
        checkboxBorderHovered: palette.themeTertiary,

        /* checkField */
        defaultCheckFieldBackground: palette.white,
        defaultCheckFieldText: palette.neutralSecondary,
        defaultCheckFieldBorder: palette.neutralLight,
        hoverCheckFieldBackground: palette.white,
        hoverCheckFieldText: palette.neutralPrimary,
        hoverCheckFieldBorder: palette.themeLight,
        selectedCheckFieldBackground: palette.themeLighterAlt,
        selectedCheckFieldText: palette.themePrimary,
        selectedCheckFieldBorder: palette.themeTertiary,
        disabledCheckFieldBackground: palette.neutralLighter,
        disabledCheckFieldText: palette.neutralTertiaryAlt,
        disabledCheckFieldBorder: palette.neutralTertiaryAlt,

        /* commandBar */
        commandBarItemBackground: 'transparent',
        commandBarItemBackgroundHover: palette.neutralLighter,
        commandBarItemBackgroundSelected: palette.themeLighterAlt,
        commandBarItemText: palette.neutralSecondary,
        commandBarItemTextSelected: palette.themePrimary,

        /* contextualMenu */
        contextualMenuItemBackgroundDisabled: palette.neutralLighter,
        contextualMenuItemColorDisabled: palette.neutralTertiaryAlt,

        /* dialog */
        dialogTitleColor: palette.neutralPrimary,
        dialogDescriptionColor: palette.neutralPrimary,

        /* divider */
        dividerColor: palette.neutralQuaternary,

        /* dropdown */
        dropdownCaretDown: palette.neutralTertiary,

        /* elevation */
        elevation: palette.black,

        /* icons */
        iconFill: palette.neutralPrimary,

        /* icon-button */
        iconButtonGhostTertiaryColor: palette.neutralPrimary,
        iconButtonGhostTertiaryLightColor: palette.white,
        iconButtonGhostTertiaryColorHovered: palette.neutralSecondary,
        iconButtonGhostTertiaryLightColorHovered: palette.neutralLighter,
        iconButtonGhostTertiaryColorPressed: palette.neutralDark,
        iconButtonGhostTertiaryLightColorPressed: palette.neutralQuaternary,
        iconButtonGhostTertiaryLightColorFocused: palette.white,

        /* image-picker */
        imagePickerActiveBackgroundColor: palette.themeLighterAlt,
        imagePickerActiveBorderColor: palette.themeTertiary,
        imagePickerActiveColor: palette.themePrimary,
        imagePickerDashedBorder: palette.neutralQuaternary,
        imagePickerHoveredBackgroundColor: palette.neutralLighter,

        /* input */
        inputBorder: palette.neutralLight,
        inputBorderHovered: palette.neutralLight,
        inputPlaceholderText: palette.neutralTertiary,
        inputShadowHovered: palette.themeLighter,

        /* link */
        linkHovered: palette.themeTertiary,
        linkPressed: palette.themeDark,
        ghostLinkHovered: palette.neutralTertiary,
        ghostLinkPressed: palette.neutralDark,

        /* list */
        listHeaderBorder: palette.neutralQuaternary,
        listRowBorder: palette.neutralLight,
        listRowHovered: palette.neutralLighterAlt,

        /* menu */
        menuItemTextHovered: palette.themePrimary,
        menuItemBackgroundHovered: palette.neutralLighterAlt,

        /* message-bar */
        messageBarNeutralBackground: palette.neutralLighterAlt,
        messageBarValidationBackground: customizeTheme?.palette?.success,
        messageBarErrorBackground: customizeTheme?.palette?.error,
        messageBarValidationText: customizeTheme?.palette?.successDark,
        messageBarErrorText: customizeTheme?.palette?.errorDark,
        messageBarNeutralBorder: palette.neutralQuaternaryAlt,
        messageBarValidationBorder: customizeTheme?.palette?.success,
        messageBarErrorBorder: customizeTheme?.palette?.error,

        /* modal */
        modalHeaderBorder: palette.neutralLight,

        /* panel */
        panelHeaderBorder: palette.neutralLight,

        /* persona */
        personaBorder: palette.white,

        /* personas-group */
        personasGroupOverflowButtonBackground: palette.themePrimary,
        personasGroupOverflowButtonColor: palette.white,

        /* people picker */
        selectionZoneBorder: palette.neutralLight,

        /* people pill */
        peoplePillPrimaryBackground: palette.themePrimary,
        peoplePillSecondaryBackground: palette.themeLighterAlt,
        neutralPeoplePillSecondaryBackground: palette.neutralLighterAlt,
        peoplePillPrimaryColor: palette.white,
        peoplePillSecondaryColor: palette.themePrimary,
        neutralPeoplePillSecondaryColor: palette.neutralPrimary,

        /* search-box */
        searchBoxPrimaryBackground: palette.neutralLighterAlt,

        /* spinner */
        spinnerBackground: palette.themePrimary,
        spinnerText: palette.neutralPrimary,

        /* tabs */
        tabBackground: 'transparent',
        tabBackgroundHover: palette.neutralLighter,
        tabBackgroundSelected: palette.themeLighterAlt,
        tabText: palette.neutralSecondary,
        tabTextSelected: palette.themePrimary,
        tabRightBorder: palette.neutralLighter,

        /* toggle */
        togglePillOnBackground: palette.themePrimary,
        togglePillOffBackground: palette.neutralQuaternary,
        toggleThumbOnBackground: palette.white,
        toggleThumbOffBackground: palette.white,
        toggleText: palette.black,

        /* Errors */
        errorBackground: palette.red,
        errorText: palette.redDark,

        /* Stepper */
        stepperFinishedBackground: palette.themePrimary,
        stepperFinishedText: palette.themePrimary,
        stepperInProgressText: palette.white,
        stepperInProgressBackground: palette.themePrimary,
        stepperWaitText: palette.neutralTertiary,
        stepperWaitBackground: palette.neutralTertiary,
        stepperTitle: palette.neutralPrimary,
        stepperDescription: palette.neutralSecondary,
        stepperFinishedBorder: palette.themePrimary,
        stepperInProgressBorder: palette.white,
        stepperWaitBorder: palette.neutralTertiary,
        stepperErrorBorder: palette.redDark,

        /* tag */
        primaryTagBackground: palette.themePrimary,
        primaryTagText: palette.white,
        secondaryTagBackground: palette.themeLighterAlt,
        secondaryTagText: palette.themePrimary,
        transparentLightTagBackground: palette.white,
        transparentLightTagText: palette.neutralPrimary,
        transparentDarkTagBackground: palette.black,
        transparentDarkTagText: palette.white,

        /* Tree-view-item */
        TreeViewItemTextSelected: palette.neutralTertiary,

        /* Radio */
        radioBackground: palette.white,
        radioBackgroundDisabled: palette.neutralLighter,
        radioBackgroundSelected: palette.themeLighterAlt,
        radioBorder: palette.neutralLight,
        radioBorderDisabled: palette.neutralLight,
        radioBorderHover: palette.themeLight,
        radioBorderSelected: palette.themePrimary,
        radioChoiceFieldBackgroundSelected: palette.themeLighterAlt,
        radioIcon: palette.neutralPrimary,
        radioIconDisabled: palette.neutralTertiaryAlt,
        radioIconSelected: palette.themePrimary,
        radioText: palette.neutralPrimary,
        radioTextDisabled: palette.neutralTertiaryAlt,
        radioTextSelected: palette.themePrimary,
    } as ISemanticColors;

    const mozzaikTheme = Fluent.mergeThemes(originalTheme, customizeTheme) as Theme;

    return mozzaikTheme;
}
