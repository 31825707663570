import * as Fluent from '@fluentui/react';
import { IMenuOption } from '@shared';
import { Theme } from '@theme';
import * as Constants from './Button.constants';
import { ButtonClass, ButtonRadius, ButtonSize, ButtonSizeUnion, ButtonType, IButtonProps } from './Button.types';

const getIconSize = (size?: ButtonSize | ButtonSizeUnion) => {
    switch (size) {
        case ButtonSize.Small:
        case 'small':
            return Constants.ICON_SMALL_SIZE;
        case ButtonSize.Large:
        case 'large':
            return Constants.ICON_LARGE_SIZE;
        case ButtonSize.Medium:
        case 'medium':
        default:
            return Constants.ICON_MEDIUM_SIZE;
    }
};

const getIconFontSize = (size?: ButtonSize | ButtonSizeUnion) => {
    switch (size) {
        case ButtonSize.Small:
        case 'small':
            return Constants.ICON_SMALL_FONT_SIZE;
        case ButtonSize.Large:
        case 'large':
            return Constants.ICON_LARGE_FONT_SIZE;
        case ButtonSize.Medium:
        case 'medium':
        default:
            return Constants.ICON_MEDIUM_FONT_SIZE;
    }
};

interface IButtonTokens {
    buttonContent: Fluent.IStackTokens;
}

export const ButtonTokens: IButtonTokens = {
    buttonContent: {
        childrenGap: Constants.ICON_GAP,
    },
};

interface IButtonStyles {
    button: (props: IButtonProps, theme: Theme) => Fluent.IButtonStyles;
    label: Fluent.ITextStyles;
    menu: Partial<Fluent.IContextualMenuStyles>;
    menuItem: (menuProps: IMenuOption, theme: Theme) => Partial<Fluent.IContextualMenuItemStyles>;
    buttonTextContainer: Fluent.IStackStyles;
}

export const ButtonStyles: IButtonStyles = {
    button: (props, theme) => {
        const { buttonClass = 'standard', horizontalFill, type = 'primary', radius = 'square', size = 'medium' } = props;
        const { semanticColors } = theme;

        const iconSize = getIconSize(size);
        const iconFontSize = getIconFontSize(size);
        const iconStyles = {
            fontSize: iconFontSize,
            width: iconSize,
            height: iconSize,
            lineHeight: iconSize,
        };

        const rootStyles = [
            {
                backgroundColor: semanticColors.buttonBackground,
                borderWidth: Constants.BORDER_WIDTH,
                borderStyle: 'solid',
                borderColor: semanticColors.buttonBorder,
                color: semanticColors.buttonText,
                width: horizontalFill ? '100%' : 'max-content',
                height: 'auto',
                padding: `${Constants.PADDING_Y_MEDIUM}px ${Constants.PADDING_X}px`,
                borderRadius: 0,
                minWidth: 'auto',
            },
            (type === ButtonType.Primary || type === 'primary') && {
                backgroundColor: semanticColors.primaryButtonBackground,
                borderColor: semanticColors.primaryButtonBackground,
                color: semanticColors.primaryButtonText,
            },
            (type === ButtonType.Tertiary || type === 'tertiary') && {
                borderColor: 'transparent',
            },
            (type === ButtonType.Dashed || type === 'dashed') && {
                borderStyle: 'dashed',
            },
            (type === ButtonType.Link || type === 'link') && {
                borderWidth: 0,
                padding: 0,
            },
            (type === ButtonType.Text || type === 'text') && {
                borderColor: 'transparent',
                color: semanticColors.bodyText,
            },
            (buttonClass === ButtonClass.Ghost || buttonClass === 'ghost') && [
                {
                    backgroundColor: 'transparent',
                },
                (type === ButtonType.Primary || type === 'primary') && {
                    color: semanticColors.buttonText,
                },
                (type === ButtonType.Secondary || type === 'secondary' || type === ButtonType.Dashed || type === 'dashed') && {
                    borderColor: semanticColors.primaryButtonText,
                    color: semanticColors.primaryButtonText,
                },
            ],
            (size === ButtonSize.Small || size === 'small') && [
                type !== ButtonType.Link &&
                    type !== 'link' && {
                        paddingBottom: Constants.PADDING_Y_SMALL,
                        paddingTop: Constants.PADDING_Y_SMALL,
                    },
            ],
            (size === ButtonSize.Large || size === 'large') && {
                paddingBottom: Constants.PADDING_Y_LARGE,
                paddingTop: Constants.PADDING_Y_LARGE,
            },
            (radius === ButtonRadius.Small || radius === 'small') && {
                borderRadius: Constants.BORDER_RADIUS_SMALL,
            },
            (radius === ButtonRadius.Medium || radius === 'medium') && {
                borderRadius: Constants.BORDER_RADIUS_MEDIUM,
            },
            (radius === ButtonRadius.Circle || radius === 'circle') && {
                borderRadius: Constants.BORDER_RADIUS_LARGE,
            },
        ];

        const rootHoveredStyles = [
            {
                backgroundColor: semanticColors.buttonBackgroundHovered,
                color: semanticColors.buttonTextHovered,
                borderColor: semanticColors.buttonBorderHovered,
            },
            (type === ButtonType.Primary || type === 'primary') && {
                backgroundColor: semanticColors.primaryButtonBackgroundHovered,
                borderColor: semanticColors.primaryButtonBackgroundHovered,
                color: semanticColors.primaryButtonTextHovered,
            },
            (type === ButtonType.Text || type === 'text') && {
                borderColor: 'transparent',
                color: semanticColors.bodyTextHovered,
            },
            (type === ButtonType.Tertiary || type === 'tertiary') && {
                borderColor: 'transparent',
            },
            (buttonClass === ButtonClass.Ghost || buttonClass === 'ghost') && [
                {
                    backgroundColor: 'transparent',
                },
                (type === ButtonType.Primary || type === 'primary') && {
                    color: semanticColors.buttonTextHovered,
                },
                (type === ButtonType.Text || type === 'text') && {
                    color: semanticColors.neutralButtonTextHovered,
                },
            ],
        ];

        const rootPressedStyles = [
            {
                backgroundColor: semanticColors.buttonBackgroundPressed,
                borderColor: semanticColors.buttonBorderPressed,
                color: semanticColors.buttonTextPressed,
            },
            (type === ButtonType.Primary || type === 'primary') && {
                backgroundColor: semanticColors.primaryButtonBackgroundPressed,
                borderColor: semanticColors.primaryButtonBackgroundPressed,
                color: semanticColors.primaryButtonTextPressed,
            },
            (type === ButtonType.Text || type === 'text') && {
                borderColor: 'transparent',
                color: semanticColors.buttonTextChecked,
            },
            (type === ButtonType.Tertiary || type === 'tertiary') && {
                borderColor: 'transparent',
            },
            (buttonClass === ButtonClass.Ghost || buttonClass === 'ghost') && [
                {
                    backgroundColor: 'transparent',
                },
                (type === ButtonType.Primary || type === 'primary') && {
                    color: semanticColors.buttonTextPressed,
                },
            ],
        ];

        return {
            root: rootStyles,
            rootExpanded: rootStyles,
            rootHovered: rootHoveredStyles,
            rootExpandedHovered: [
                rootHoveredStyles,
                {
                    ':active': rootPressedStyles,
                },
            ],
            rootPressed: rootPressedStyles,
            rootDisabled: [
                {
                    backgroundColor: semanticColors.buttonBackgroundDisabled,
                    color: semanticColors.buttonTextDisabled,
                    borderColor: semanticColors.buttonBorderDisabled,
                },
                (type === ButtonType.Primary || type === 'primary') && {
                    borderColor: semanticColors.buttonBackgroundDisabled,
                },
                (type === ButtonType.Tertiary || type === 'tertiary') && {
                    backgroundColor: 'transparent',
                },
                (type === ButtonType.Link || type === 'link') && {
                    backgroundColor: 'transparent',
                },
                (type === ButtonType.Text || type === 'text') && {
                    borderColor: 'transparent',
                },
                (buttonClass === ButtonClass.Ghost || buttonClass === 'ghost') && {
                    backgroundColor: 'transparent',
                },
            ],
            flexContainer: {
                fontSize: Constants.FONT_SIZE,
                fontWeight: Constants.FONT_WEIGHT,
                margin: 0,
                padding: '0 0 0 1px',
                lineHeight: Constants.LINE_HEIGHT,
                height: Constants.LINE_HEIGHT,
            },
            icon: [
                iconStyles,
                {
                    marginLeft: 0,
                    marginRight: Constants.ICON_GAP,
                },
            ],
            menuIcon: {
                display: 'none',
            },
        };
    },
    label: {
        root: {
            color: 'currentColor',
        },
    },
    menu: {
        root: {
            borderRadius: Constants.MENU_RADIUS,
            padding: Constants.MENU_PADDING,
            minWidth: Constants.MENU_MIN_WIDTH,
        },
    },
    menuItem: (menuProps, theme) => {
        const { menuLabelColor = theme.semanticColors.defaultButtonLabelMenu } = menuProps;
        return {
            root: {
                ':hover': {
                    backgroundColor: theme.semanticColors.menuItemBackgroundHovered,
                    color: theme.semanticColors.menuItemTextHovered,
                },
                ':active': {
                    backgroundColor: theme.semanticColors.menuItemBackgroundHovered,
                },
            },
            label: {
                color: menuLabelColor,
            },
        };
    },
    buttonTextContainer: {
        root: {
            p: {
                cursor: 'pointer',
            },
        },
    },
};
