import { IIcons } from '@tokens';

export interface ISwitcherProps {
    /** The key of the option that will be initially checked. */
    defaultSelectedKey: string;
    /** A callback for receiving a notification when the choice has been changed. */
    onChange?: (option: ISwitcherOption) => void;
    /** The options for the switcher. */
    options: ISwitcherOption[];
    /** The border radius of the switcher. */
    radius?: SwitcherRadius;
    /** The key of the option that is checked. */
    selectedKey?: string;
}

export interface ISwitcherOption {
    /** A required key to uniquely identify the option. */
    key: string;
    /** The name of the icon to use from the icon font. */
    iconName?: keyof IIcons;
    /** Aria label of the option for the benefit of screen reader users. */
    ariaLabel?: string;
    /** Title for options with text */
    title?: string;
}

export enum SwitcherRadius {
    Square = 'Square',
    Small = 'Small',
    Medium = 'Medium',
    Circle = 'Circle',
}
