import React, { useMemo } from 'react';
import * as Fluent from '@fluentui/react';
import { useTheme } from '@theme';
import { PeoplePillStyles as styles } from './PeoplePill.styles';
import { IPeoplePillProps } from './PeoplePill.types';

export const PeoplePill: React.FC<IPeoplePillProps> = (props) => {
    const { index, item, onRemoveItem } = props;

    //extract the imageUrl from the item and set it to the state
    const { imageUrl: initialImage } = item;
    const initialImageUrl = typeof initialImage === 'string' ? initialImage : undefined;

    const [imageUrl, setImageUrl] = React.useState<string | undefined>(initialImageUrl);

    const theme = useTheme();

    //If the initial image is a function or a promise, set the state with the new item with the imageUrl
    React.useEffect(() => {
        if (initialImage instanceof Function) {
            Promise.resolve(initialImage()).then((url) => {
                setImageUrl(url);
            });
        }
    }, [initialImage]);

    const itemForFluent: Fluent.IPersonaProps & { ValidationState: Fluent.ValidationState } = useMemo(() => {
        return {
            ...item,
            imageUrl: imageUrl,
            ValidationState: Fluent.ValidationState.valid,
        };
    }, [item, imageUrl]);

    return (
        <Fluent.PeoplePickerItem
            index={index}
            item={itemForFluent}
            onRemoveItem={() => onRemoveItem(item)}
            styles={styles.peoplePill(props, theme)}
            removeButtonIconProps={{ iconName: 'Cancel', styles: styles.removeIconButton }}
        />
    );
};
