import * as Fluent from '@fluentui/react';
import { getColorFromHex, getModalShadowStyle } from '@styles';
import { Theme } from '@theme';
import * as Constants from './Modal.constants';
import { IModalProps } from './Modal.types';

interface IModalStyles {
    modal: (props: IModalProps, theme: Theme) => Partial<Fluent.IModalStyles>;
    overlay: (theme: Theme, props?: IModalProps) => Partial<Fluent.IOverlayStyles>;
}

const fullScreenStyle = {
    '&.ms-Dialog-main': {
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        borderRadius: Constants.FULLSCREEN_RADIUS,
        animation: Fluent.MotionAnimations.slideUpIn,
        animationDuration: Fluent.MotionDurations.duration4,
    },
};

export const ModalStyles: IModalStyles = {
    modal: (props, theme) => {
        const { palette } = theme;
        const { borderLess, fullScreen, height, width } = props;

        if (borderLess) {
            return {
                main: [
                    {
                        width: width ?? Constants.BORDERLESS_SIZE,
                        height: height ?? Constants.BORDERLESS_SIZE,
                        background: 'transparent',
                        boxShadow: 'none',
                    },
                    fullScreen && fullScreenStyle,
                ],
                scrollableContent: {
                    maxHeight: '100%',
                    height: '100%',
                    overflow: 'hidden',
                },
            };
        }

        return {
            main: [
                {
                    width: width ?? Constants.WIDTH,
                    height: height ?? Constants.HEIGHT,
                    borderRadius: Constants.BORDER_RADIUS,
                },
                fullScreen && fullScreenStyle,
                getModalShadowStyle(palette.black, palette.black),
            ],
            scrollableContent: {
                height: '100%',
            },
        };
    },
    overlay: (theme, props) => {
        const { palette } = theme;
        if (props?.borderLess) {
            return {
                boxShadow: 'none',
            };
        }
        return {
            root: {
                background: getColorFromHex(palette.black, 40),
                backdropFilter: 'blur(2px)',
            },
        };
    },
};
