import { PropsWithChildren } from 'react';
import * as Fluent from '@fluentui/react';
import { Theme } from '@theme';
import * as Constants from './Persona.constants';
import { IPersonaProps, PersonaShape, PersonaSize } from './Persona.types';

interface IPersonaStyles {
    persona(props: PropsWithChildren<IPersonaProps>): Fluent.IStyleFunctionOrObject<Fluent.IPersonaStyleProps, Fluent.IPersonaStyles> | undefined;
    coin: (props: IPersonaProps, theme: Theme) => Fluent.IPersonaCoinStyles;
}

export const PersonaStyles: IPersonaStyles = {
    persona: (props) => {
        const { weight, color, personaGap = 0, avatarPosition = 'left' } = props;

        return {
            root: [
                avatarPosition === 'right' && {
                    flexDirection: 'row-reverse',
                    width: 'fit-content',
                },
                {
                    gap: personaGap,
                },
            ],
            details: {
                padding: avatarPosition === 'right' ? `0 ${Constants.GAP}px 0 0` : `0 0 0 ${Constants.GAP}px`,
            },
            primaryText: [
                {
                    ':hover': {
                        color: 'inherit',
                    },
                },
                weight && {
                    fontWeight: weight,
                },
                color && {
                    color,
                    ':hover': {
                        color,
                    },
                },
            ],
        };
    },

    coin: (props, theme) => {
        const { shape = PersonaShape.Circle, size = PersonaSize.Size48, withBorder } = props;

        // border styles
        const borderStyles = {
            borderWidth: size === PersonaSize.Size16 ? Constants.SMALL_PERSONA_BORDER_WIDTH : Constants.BORDER_WIDTH,
            borderStyle: 'solid',
            borderColor: theme.semanticColors.personaBorder,
            boxSizing: 'border-box',
        };

        // square styles
        const isSquare = shape === PersonaShape.Square;
        const squareStyles = { borderRadius: 0 };

        const coinStyles = [isSquare && squareStyles, withBorder && borderStyles];

        return {
            image: [...coinStyles],
            initials: [
                {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: Constants.fontSizeByPersoneSize.get(size),
                },
                ...coinStyles,
            ],
        } as Fluent.IPersonaCoinStyles;
    },
};
