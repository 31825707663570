import DOMPurify from 'dompurify';
import React from 'react';
import { mergeStyles } from '@fluentui/react';
import { RichTextStyles as styles } from './RichText.styles';
import { IRichTextViewerProps } from './RichText.types';

export const RichText: React.FC<IRichTextViewerProps> = (props) => {
    const { html, color, maxLine, wordBreak } = props;

    if (!html) {
        return null;
    }

    const sanitizeHtml = (originalHtml: string) => {
        return DOMPurify.sanitize(originalHtml);
    };

    return <div className={mergeStyles(styles.container(color, maxLine, wordBreak))} dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }} />;
};
