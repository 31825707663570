import React from 'react';
import * as Fluent from '@fluentui/react';
import { useTheme } from '@theme';
import { ModalContentStyles as styles, ModalContentTokens as tokens } from './ModalContent.styles';
import { IModalContentProps, IModalTwoColumns } from './ModalContent.types';

export const ModalContent: React.FC<IModalContentProps> = (props) => {
    const { content } = props;
    const theme = useTheme();

    const isValidContent = React.isValidElement(content);
    const contents = isValidContent ? [content] : [(content as IModalTwoColumns).left, (content as IModalTwoColumns).right];
    const basis = isValidContent ? undefined : '50%';

    return (
        <Fluent.Stack horizontal verticalFill styles={styles.wrapper(props, theme)}>
            {contents.map((contentItem, index) => (
                <Fluent.Stack.Item key={index} grow basis={basis} tokens={tokens.content(props)}>
                    {contentItem}
                </Fluent.Stack.Item>
            ))}
        </Fluent.Stack>
    );
};
