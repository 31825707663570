import * as Fluent from '@fluentui/react';
import { Direction } from '@shared';
import { CALLOUT_LARGE_WIDTH, CALLOUT_MEDIUM_WIDTH, CALLOUT_SMALL_WIDTH } from './Callout.constants';

export interface ICalloutProps {
    /** ID of the element which contains the description for the callout. */
    ariaDescribedBy?: string;
    /** ID of the element which contains label text for the callout. */
    ariaLabelledBy?: string;
    /** How the element should be positioned */
    directionalHint?: Direction;
    /** If true the position will not change sides in an attempt to fit the callout within bounds. */
    directionalHintFixed?: boolean;
    /** Default to false, allow to display a Beak (or arrow) to show the source of the callout call */
    isBeakVisible?: boolean;
    /** Defines The minimum distance the callout will be away from the edge of the screen. */
    minPagePadding?: number;
    /** Callback when the Callout tries to close. */
    onDismiss?: () => void;
    /** Overflow behavior of the Callout, hidden by default */
    overflow?: CalloutOverflow;
    /** Define the padding inside the callout */
    padding?: CalloutPadding;
    /** Defines if the callout will attempt to focus the first focusable element that it contains */
    setInitialFocus?: boolean;
    /** The target that the Callout should try to position itself based on.  */
    target?: Fluent.Target;
    /** Defines the width of the callout. */
    width?: number;
}

export enum CalloutSize {
    Small = CALLOUT_SMALL_WIDTH,
    Medium = CALLOUT_MEDIUM_WIDTH,
    Large = CALLOUT_LARGE_WIDTH,
}

export type CalloutOverflow = 'visible' | 'hidden' | 'scroll' | 'auto' | 'initial' | 'inherit';

export type CalloutPadding = 'none' | 'medium';
