import React, { useMemo } from 'react';
import * as Fluent from '@fluentui/react';
import { Theme } from '@theme';
import { ModalStyles as styles } from './Modal.styles';
import { IModalProps } from './Modal.types';
import { ModalContent, ModalHeader } from './components';
import { ModalFooter } from './components/modal-footer/ModalFooter';

/**
 * Modals are temporary pop-ups that take focus from the page or app and require people to interact with them.
 * Unlike a dialog box (Dialog), a modal should be used for hosting lengthy content, such as privacy statements or license agreements,
 * or for asking people to perform complex or multiple actions, such as changing settings.
 */
export const Modal: React.FC<IModalProps> = (props) => {
    const {
        borderLess = false,
        content,
        contentBackground,
        contentPadding,
        footerProps,
        fullScreen,
        hasHeaderBorder = true,
        isOpen,
        onClose,
        onClosed,
        showHeaderCloseButton = true,
        title,
    } = props;

    const overlayProps: Fluent.IOverlayProps = {
        styles: ({ theme }) => styles.overlay(theme as Theme, props),
    };

    const hideHeader = borderLess && !showHeaderCloseButton;

    const showFooter = useMemo(() => {
        return footerProps && (footerProps.primaryButtonProps || footerProps.secondaryButtonProps);
    }, [footerProps]);

    return (
        <Fluent.Modal
            isOpen={isOpen}
            onDismiss={onClose}
            onDismissed={onClosed}
            overlay={overlayProps}
            focusTrapZoneProps={{ disableFirstFocus: true }}
            allowTouchBodyScroll
            styles={({ theme }) => styles.modal(props, theme as Theme)}>
            <Fluent.Stack horizontalAlign="center" verticalAlign="center" verticalFill>
                {!hideHeader && (
                    <ModalHeader
                        showCloseButton={showHeaderCloseButton}
                        title={title}
                        borderLess={borderLess}
                        onClose={onClose}
                        fullScreen={fullScreen}
                        hasHeaderBorder={hasHeaderBorder}
                    />
                )}
                <ModalContent
                    background={contentBackground}
                    borderLess={borderLess}
                    content={content}
                    contentPadding={contentPadding}
                    fullScreen={fullScreen}
                />
                {showFooter && footerProps && (
                    <ModalFooter
                        primaryButtonProps={footerProps.primaryButtonProps}
                        secondaryButtonProps={footerProps.secondaryButtonProps}
                        buttonsAlignment={footerProps.buttonsAlignment}
                    />
                )}
            </Fluent.Stack>
        </Fluent.Modal>
    );
};
