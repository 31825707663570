import { IIcons } from '@tokens';

export type ImageUrlPeopleType = string | (() => string) | (() => Promise<string>) | Promise<string>;

export interface IMenuProps {
    /** Menu items to display. */
    items: IMenuOption[];
    /** How the menu should be positioned. */
    directionalHint?: DirectionalHint;
}

export interface IMenuOption {
    /** Whether the menu item is disabled */
    disabled?: boolean;
    /** The key of the menu option. */
    key: string;
    /** The icon of the menu option. */
    iconName?: keyof IIcons;
    /** Defines color of label menu item. */
    menuLabelColor?: string;
    /** Optional callback when menu item is clicked. */
    onClick?: () => void;
    /** The text of the menu option. */
    text?: string;
}

export enum DirectionalHint {
    topLeftEdge,
    topCenter,
    topRightEdge,
    topAutoEdge,
    bottomLeftEdge,
    bottomCenter,
    bottomRightEdge,
    bottomAutoEdge,
    leftTopEdge,
    leftCenter,
    leftBottomEdge,
    rightTopEdge,
    rightCenter,
    rightBottomEdge,
}

export enum Direction {
    TopLeftEdge = 0,
    TopCenter = 1,
    TopRightEdge = 2,
    TopAutoEdge = 3,
    BottomLeftEdge = 4,
    BottomCenter = 5,
    BottomRightEdge = 6,
    BottomAutoEdge = 7,
    LeftTopEdge = 8,
    LeftCenter = 9,
    LeftBottomEdge = 10,
    RightTopEdge = 11,
    RightCenter = 12,
    RightBottomEdge = 13,
}
