import React, { useMemo } from 'react';
import { IconButton, IconButtonSizeType } from '@atoms';
import * as Fluent from '@fluentui/react';
import { useTheme } from '@theme';
import { FontType, FontWeights, IconTheme, Text } from '@tokens';
import { ModalHeaderStyles as styles, ModalHeaderTokens as tokens } from './ModalHeader.styles';
import { IModalHeaderProps } from './ModalHeader.types';

export const ModalHeader: React.FC<IModalHeaderProps> = (props) => {
    const { onClose, showCloseButton, title, borderLess = false, fullScreen } = props;

    const theme = useTheme();

    const iconSize = useMemo((): IconButtonSizeType => {
        if (borderLess && !fullScreen) {
            return 'medium';
        }
        return 'large';
    }, [borderLess, fullScreen]);

    const headerContent = useMemo(
        () => (typeof title === 'string' ? <Text content={title} weight={FontWeights.Semibold} type={FontType.PaneHeader} /> : title),
        [title],
    );

    return (
        <Fluent.Stack
            horizontal
            horizontalAlign={!borderLess && title ? 'space-between' : 'end'}
            verticalAlign={!borderLess ? 'center' : 'start'}
            tokens={tokens.header(props)}
            styles={styles.header(props, theme)}>
            {!borderLess && title && headerContent}
            {showCloseButton && (
                <IconButton
                    icon={{ iconName: 'Dismiss', theme: fullScreen ? IconTheme.Filled : IconTheme.Regular }}
                    ariaLabel="Close"
                    buttonClass={borderLess ? 'ghost' : 'neutral'}
                    type={borderLess ? 'tertiaryLight' : 'tertiary'}
                    size={iconSize}
                    onClick={onClose}
                />
            )}
        </Fluent.Stack>
    );
};
