import * as Fluent from '@fluentui/react';
import { getInputFocusStyle, getScrollbarStyles, getShadowStyle } from '@styles';
import { Theme } from '@theme';
import * as Constants from './PeoplePicker.constants';
import { IPeoplePickerProps } from './PeoplePicker.types';

interface IPeoplePickerStyles {
    peoplePicker: (props: Fluent.IBasePickerStyleProps, componentProps: IPeoplePickerProps, theme: Theme) => Partial<Fluent.IPeoplePickerItemSelectedStyles>;
    suggestionsCallout: (theme: Theme) => Fluent.IStyleFunctionOrObject<Fluent.ICalloutContentStyleProps, Fluent.ICalloutContentStyles>;
}
export const PeoplePickerStyles: IPeoplePickerStyles = {
    peoplePicker: (props, componentProps, theme) => {
        const { isFocused } = props;
        const { peoplePickerVariation } = componentProps;
        const { semanticColors } = theme;

        return {
            root: [
                {
                    '.ms-BasePicker-text': [
                        {
                            'padding': '4px 12px',
                            'borderRadius': Constants.PEOPLE_PICKER_RADIUS,
                            ':hover': [getShadowStyle(semanticColors.inputShadowHovered)],
                        },
                        isFocused && [getInputFocusStyle(semanticColors.inputShadowHovered, semanticColors.inputFocusBorderAlt, 4)],
                    ],
                },
                {
                    '.ms-BasePicker-itemsWrapper': {
                        gap: Constants.ITEMS_GAP,
                    },
                },
                peoplePickerVariation === 'list' && {
                    '~ div': [
                        {
                            border: `1px solid ${semanticColors.selectionZoneBorder}`,
                            borderRadius: Constants.PEOPLE_PICKER_RADIUS,
                            marginTop: Constants.SELECTION_ZONE_MARGIN_TOP,
                            padding: Constants.SELECTION_ZONE_PADDING,
                            minHeight: Constants.SELECTION_ZONE_HEIGHT,
                        },
                        {
                            '.ms-PickerPersona-container': {
                                margin: Constants.SELECTION_ITEM_MARGIN,
                            },
                        },
                    ],
                },
            ],
        };
    },
    suggestionsCallout: (theme) => ({
        root: {
            '.ms-Suggestions-container': getScrollbarStyles(theme),
            'marginTop': Constants.CALLOUT_MARGIN_TOP,
        },
    }),
};
