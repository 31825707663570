import { IIconProps, IIcons } from '@tokens';

export enum MessageBarType {
    Neutral = 'Neutral',
    Validation = 'Validation',
    Error = 'Error',
}

export interface IMessageBarContentLinkProps {
    href?: string;
    target?: string;
    onClick?: () => void;
    label: string;
}

export interface IMessageBarProps {
    title: string;
    content: string;
    contentLink?: IMessageBarContentLinkProps;
    messageBarIcon?: keyof IIcons;
    expandButtonProps?: IIconProps;
    type?: MessageBarType;
    actions?: JSX.Element;
    multiline?: boolean;
    onDismiss?: () => void;
}
