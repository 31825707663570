import React from 'react';
import * as Fluent from '@fluentui/react';
import { useTheme } from '@theme';
import { Icon } from '@tokens';
import { iconSizeByPersonaSize, personaSizeMapping } from './Persona.constants';
import { PersonaStyles as styles } from './Persona.styles';
import { IPersonaProps, PersonaSize } from './Persona.types';

/**
 * Avatars can be used to represent people or objects. It supports images, Icon or Text
 */
export const Persona: React.FC<IPersonaProps> = (props) => {
    const { displayName, hideDisplayName = false, imageUrl: initialImage, size = PersonaSize.Size48, showOverflowTooltip = true, dataTestId } = props;
    const personaSize = personaSizeMapping.get(size) ?? Fluent.PersonaSize.size48;
    // for lazy loading of image, initialImage can be a function that returns a promise of the image url
    const initialImageUrl = typeof initialImage === 'string' ? initialImage : undefined;

    const [imageUrl, setImageUrl] = React.useState<string | undefined>(initialImageUrl);

    React.useEffect(() => {
        if (initialImage instanceof Function) {
            Promise.resolve(initialImage()).then((url) => {
                setImageUrl(url);
            });
        } else if (typeof initialImage === 'string') {
            setImageUrl(initialImage);
        }
    }, [initialImage]);

    const theme = useTheme();

    const onRenderInitials: Fluent.IRenderFunction<Fluent.IPersonaSharedProps> = (fluentProps, defaultRender) => {
        if (!fluentProps || !defaultRender) {
            return null;
        }

        fluentProps.text = size === PersonaSize.Size16 ? displayName?.split(' ')[0] : displayName;

        // customize icon if initials are not defined (empty string)
        const initials = Fluent.getInitials(fluentProps.text, false);
        if (initials === '') {
            const iconSize = iconSizeByPersonaSize.get(size);
            return <Icon iconName="Person" size={iconSize} />;
        }

        return defaultRender(fluentProps);
    };

    return (
        <Fluent.Persona
            text={displayName}
            size={personaSize}
            imageUrl={imageUrl}
            hidePersonaDetails={hideDisplayName}
            onRenderInitials={onRenderInitials}
            coinProps={{ styles: styles.coin(props, theme) }}
            showOverflowTooltip={showOverflowTooltip}
            styles={styles.persona(props)}
            data-testid={dataTestId}
        />
    );
};
