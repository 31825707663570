import * as Fluent from '@fluentui/react';
import { IMember } from './PeoplePicker.types';

export const convertToPersonasFromMembers = (items: IMember[]) =>
    items.map((item) => ({
        id: item.id,
        imageUrl: typeof item.imageUrl === 'string' ? item.imageUrl : undefined,
        text: item.displayName,
    }));

export const convertToMembersFromPersonas = (items: Fluent.IPersonaProps[], inputMembers: IMember[]) =>
    items.map((item) => convertToMemberFromPersona(item, inputMembers));

export const convertToMemberFromPersona = (item: Fluent.IPersonaProps, inputMembers: IMember[]) => ({
    id: item.id,
    displayName: item.text,
    imageUrl: inputMembers.find((member) => member.id === item.id)?.imageUrl,
});
