import * as Fluent from '@fluentui/react';
import { Theme } from '@theme';
import * as Constants from './ModalHeader.constants';
import { IModalHeaderProps } from './ModalHeader.types';

interface IModalHeaderTokens {
    header: (props: IModalHeaderProps) => Fluent.IStackTokens;
}

interface IModalHeaderStyles {
    header: (props: IModalHeaderProps, theme: Theme) => Fluent.IStackStyles;
}

export const ModalHeaderTokens: IModalHeaderTokens = {
    header: (props) => {
        const { borderLess = false } = props;
        const padding = borderLess ? 0 : `${Constants.PADDING_Y}px ${Constants.PADDING_X}px`;
        const background = borderLess ? 'transparent' : undefined;

        return {
            padding,
            background,
        };
    },
};

export const ModalHeaderStyles: IModalHeaderStyles = {
    header: (props, theme) => {
        const { borderLess = false, hasHeaderBorder } = props;

        if (borderLess) {
            return {
                root: {
                    height: Constants.HEIGHT_HEADERLESS,
                    background: 'transparent',
                    width: '100%',
                },
            };
        }
        return {
            root: {
                borderBottom: hasHeaderBorder ? `1px solid ${theme.semanticColors.modalHeaderBorder}` : undefined,
                height: Constants.HEIGHT,
                width: '100%',
            },
        };
    },
};
