export interface IStepperItemProps {
    status: StepperItemStatus;
    title: string;
    description?: string;
    vertical?: boolean;
    step: string;
    noDivider: boolean;
}

export enum StepperItemStatus {
    InProgress = 'InProgress',
    Finished = 'Finished',
    Wait = 'Wait',
    Error = 'Error',
}
