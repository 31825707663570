import * as Fluent from '@fluentui/react';

export const getInputFocusStyle = (
    boxShadoxColor: string,
    borderColor: string,
    borderRadius: string | number,
    borderType: 'border' | 'borderBottom' = 'border',
    borderPosition = -1,
): Fluent.IStyle => {
    return [
        Fluent.getInputFocusStyle(borderColor, borderRadius, borderType, borderPosition),
        {
            boxShadow: `0px 0px 0px 2px ${boxShadoxColor}`,
        },
        {
            ':after': {
                borderWidth: 1,
            },
        },
    ];
};
