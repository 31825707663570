import * as Fluent from '@fluentui/react';
import { Theme } from '@theme';
import { FontSizes, FontWeights } from '@tokens';
import { IPeoplePillProps } from './PeoplePill.types';

interface IPeoplePillStyles {
    peoplePill: (
        props: IPeoplePillProps,
        theme: Theme,
    ) => Fluent.IStyleFunctionOrObject<Fluent.IPeoplePickerItemSuggestionStyleProps, Fluent.IPeoplePickerItemSuggestionStyles>;
    removeIconButton: Fluent.IIconStyles;
}

export const PeoplePillStyles: IPeoplePillStyles = {
    peoplePill: (props, theme) => {
        const { type, peoplePillClass, hideRemoveButton = false } = props;
        const { semanticColors } = theme;

        return {
            root: [
                {
                    background: semanticColors.peoplePillPrimaryBackground,
                    padding: '2px 8px 2px 2px',
                    margin: 0,
                },
                {
                    '.ms-Persona-details': {
                        padding: '0 12px 0 4px',
                    },
                    '.ms-Persona-primaryText': {
                        color: semanticColors.peoplePillPrimaryColor,
                        fontSize: FontSizes.Size12,
                        fontWeight: FontWeights.Semibold,
                    },
                    '.ms-Button': [
                        {
                            display: hideRemoveButton && 'none',
                            backgroundColor: 'transparent',
                            color: semanticColors.peoplePillPrimaryColor,
                        },
                        {
                            ':hover': {
                                backgroundColor: 'transparent',

                                color: semanticColors.ghostTertiaryLightButtonHovered,
                            },
                            ':active': {
                                backgroundColor: 'transparent',

                                color: semanticColors.ghostTertiaryLightButtonPressed,
                            },
                        },
                    ],
                    ':hover': [
                        {
                            background: semanticColors.peoplePillPrimaryBackground,
                            color: semanticColors.peoplePillSecondaryColor,
                        },
                        {
                            '.ms-Persona-primaryText': {
                                color: semanticColors.peoplePillPrimaryColor,
                            },
                        },
                    ],
                },
                type === 'secondary' && [
                    {
                        background: semanticColors.peoplePillSecondaryBackground,
                        color: semanticColors.peoplePillSecondaryColor,
                    },
                    {
                        '.ms-Persona-primaryText': {
                            color: semanticColors.peoplePillSecondaryColor,
                        },
                        '.ms-Button': [
                            {
                                display: hideRemoveButton && 'none',
                                backgroundColor: 'transparent',
                                color: semanticColors.peoplePillSecondaryColor,
                            },
                            {
                                ':hover': {
                                    color: semanticColors.buttonTextHovered,
                                },
                                ':active': {
                                    color: semanticColors.buttonTextPressed,
                                },
                            },
                        ],
                        ':hover': [
                            {
                                background: semanticColors.peoplePillSecondaryBackground,
                            },
                            {
                                '.ms-Persona-primaryText': {
                                    color: semanticColors.peoplePillSecondaryColor,
                                },
                            },
                        ],
                    },
                    peoplePillClass === 'neutral' && [
                        {
                            background: semanticColors.neutralPeoplePillSecondaryBackground,
                            color: semanticColors.neutralPeoplePillSecondaryColor,
                        },
                        {
                            '.ms-Persona-primaryText': {
                                color: semanticColors.neutralPeoplePillSecondaryColor,
                            },
                            '.ms-Button': [
                                {
                                    display: hideRemoveButton && 'none',
                                    backgroundColor: 'transparent',
                                    color: semanticColors.neutralPeoplePillSecondaryColor,
                                },
                                {
                                    ':hover': {
                                        color: semanticColors.buttonTextHovered,
                                    },
                                    ':active': {
                                        color: semanticColors.buttonTextPressed,
                                    },
                                },
                            ],
                            ':hover': [
                                {
                                    background: semanticColors.neutralPeoplePillSecondaryBackground,
                                },
                                {
                                    '.ms-Persona-primaryText': {
                                        color: semanticColors.neutralPeoplePillSecondaryColor,
                                    },
                                },
                            ],
                        },
                    ],
                ],
            ],
        };
    },
    removeIconButton: {
        root: {
            fontSize: FontSizes.Size14,
        },
    },
};
