import React from 'react';
import * as Fluent from '@fluentui/react';
import { IIconProps } from '@tokens';

export interface ITabsProps {
    /** Key of the selected tab by default */
    defaultSelectedKey?: string;
    /** Items representing each tab. */
    items: ITabItemProps[];
    /** Optional callback for when the selected pivot item is changed. */
    onTabClick?: (args?: ITabClickArgs) => void;
    /** The border radius of each tab. TabRadius.Square by default. */
    radius?: TabRadius;
    /** The size of each tab. TabSize.Medium by default. */
    size?: TabSize;
    /** The tab item take the full width of its container. */
    fullWidth?: boolean;
    /** Defines if tab is vertical. */
    vertical?: boolean;
    overflow?: boolean;
    gap?: number;
}

export interface ITabItemProps {
    /** Content of the tab */
    content: React.ReactNode;
    /** Whether the tab is disabled. */
    disabled?: boolean;
    /** The text displayed on tab. */
    headerText: string;
    /** The name of the left icon to use from the icon font. */
    leftIcon?: IIconProps;
    /** An optional right icon to show next to the header tab. */
    rightIcon?: IIconProps;
    /** An required key to uniquely identify a pivot item. */
    itemKey: string;
}

export interface ITabClickArgs {
    tab?: ITabItemProps;
}

export type RenderItemTab = (
    item: ITabItemProps,
    link?: Fluent.IPivotItemProps,
    defaultRenderer?: (props?: Fluent.IPivotItemProps | undefined) => JSX.Element | null,
) => JSX.Element | null;

export enum TabSize {
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
}

export enum TabRadius {
    Square = 'Square',
    Small = 'Small',
    Medium = 'Medium',
    Circle = 'Circle',
}
