import * as Fluent from '@fluentui/react';

export interface ICommandBarProps {
    leftItems: ICommandBarItemProps[];
    rightItems?: Fluent.ICommandBarItemProps[];
    radius?: CommandBarItemRadius;
    selectedLeftItemKey?: string;
    size?: CommandBarItemSize;
    mode?: 'legacy' | 'tabs';
}

export interface ICommandBarItemProps extends Partial<Fluent.IButton> {
    key: string;
    text: string;
    /** deprecated for tabs mode - use onSelectedItem instead, this method is confusing because it is called without the event */
    onClick?: () => void;
    onSelectedItem?: (itemKey: string) => void;
    itemRadius?: CommandBarItemRadius;
    itemSize?: CommandBarItemSize;
}

export enum CommandBarItemSize {
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
}

export enum CommandBarItemRadius {
    Square = 'Square',
    Small = 'Small',
    Medium = 'Medium',
    Circle = 'Circle',
}
