import { createInstance } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { i18n as i18nDesignSystemLib } from '@mozzaik365/mozzaik-ui';
import { modaObsoleteLocales } from './_moda/locales/resources';
import { employeeDirectoryLocales } from './employee-directory/locales/resources';
import { eventsLocales } from './events/locales/resources';
import { focusLocales } from './focus/locales/ressources';
import { imageMapLocales } from './image-map/locales/resources';
import { linksLocales } from './links/locales/resources';
import { metricsLocales } from './metrics/locales/resources';
import { myEmailsLocales } from './my-emails/locales/resources';
import { myFilesLocales } from './my-files/locales/resources';
import { myMeetingsLocales } from './my-meetings/locales/resources';
import { mySummaryLocales } from './my-summary/locales/resources';
import { myTasksLocales as myTasks2Locales } from './my-tasks2/locales/resources';
import { myTasksLocales } from './my-tasks/locales/resources';
import { newsHubLocales } from './news-hub/locales/resources';
import { newsLocales } from './news/locales/resources';
import { peopleLocales } from './people/locales/resources';
import { profileLocales } from './profile/locales/resources';
import { searchResultsLocales } from './search-results/locales/resources';

const resources = {
  en: {
    employeeDirectory: employeeDirectoryLocales.en,
    events: eventsLocales.en,
    focus: focusLocales.en,
    imageMap: imageMapLocales.en,
    links: linksLocales.en,
    metrics: metricsLocales.en,
    modaObsolete: modaObsoleteLocales.en,
    myEmails: myEmailsLocales.en,
    myFiles: myFilesLocales.en,
    myMeetings: myMeetingsLocales.en,
    mySummary: mySummaryLocales.en,
    myTasks: myTasksLocales.en,
    myTasks2: myTasks2Locales.en,
    news: newsLocales.en,
    newshub: newsHubLocales.en,
    people: peopleLocales.en,
    profile: profileLocales.en,
    searchResults: searchResultsLocales.en,
  },
  fr: {
    employeeDirectory: employeeDirectoryLocales.fr,
    events: eventsLocales.fr,
    focus: focusLocales.fr,
    imageMap: imageMapLocales.fr,
    links: linksLocales.fr,
    metrics: metricsLocales.fr,
    modaObsolete: modaObsoleteLocales.fr,
    myEmails: myEmailsLocales.fr,
    myFiles: myFilesLocales.fr,
    myMeetings: myMeetingsLocales.fr,
    mySummary: mySummaryLocales.fr,
    myTasks: myTasksLocales.fr,
    myTasks2: myTasks2Locales.fr,
    news: newsLocales.fr,
    newshub: newsHubLocales.fr,
    people: peopleLocales.fr,
    profile: profileLocales.fr,
    searchResults: searchResultsLocales.fr,
  },
};

const i18n = createInstance({
  detection: {
    order: ['navigator'],
  },
  fallbackLng: 'en',
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});

i18n.use(initReactI18next).use(LanguageDetector).init();
i18n.on('languageChanged', (lng) => {
  i18nDesignSystemLib.changeLanguage(lng);
});

export default i18n;
