import { Resource } from 'i18next';
import { colorPickerLocales } from '../color-picker/locales/resources';

export const organismLocales: Resource = {
    en: {
        colorPicker: colorPickerLocales.en,
    },
    fr: {
        colorPicker: colorPickerLocales.fr,
    },
};
