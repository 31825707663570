export const FONT_SIZE = 14;
export const FONT_WEIGHT = 400;
export const LINE_HEIGHT = 20;
export const BORDER_WIDTH = 1;
export const ICON_GAP = 8;

export const ICON_SMALL_SIZE = 12;
export const ICON_MEDIUM_SIZE = 16;
export const ICON_LARGE_SIZE = 20;
export const ICON_SMALL_FONT_SIZE = 10;
export const ICON_MEDIUM_FONT_SIZE = 12;
export const ICON_LARGE_FONT_SIZE = 16;

export const PADDING_X = 15;
export const PADDING_Y_SMALL = 1;
export const PADDING_Y_MEDIUM = 5;
export const PADDING_Y_LARGE = 9;

export const BORDER_RADIUS_SMALL = 4;
export const BORDER_RADIUS_MEDIUM = 8;
export const BORDER_RADIUS_LARGE = 100;

export const MENU_GAP_FROM_BUTTON = 4;
export const MENU_RADIUS = 4;
export const MENU_PADDING = '4px 0';
export const MENU_MIN_WIDTH = 128;
