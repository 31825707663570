import * as Fluent from '@fluentui/react';

/**
 *
 * @param hexColor given hexColor
 * @param alpha alpha in percentage (from 0 to 100)
 * @returns a color with the given alpha and hexColor
 */
export const getColorFromHex = (hexColor: string, alpha: number): string => {
    return Fluent.updateA(Fluent.getColorFromString(hexColor) || ({ str: 'white' } as Fluent.IColor), alpha).str;
};
