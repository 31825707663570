import * as Fluent from '@fluentui/react';
import { getScrollbarStyles } from '@styles';
import { Theme } from '@theme';
import * as ModalConstants from '../../Modal.constants';
import * as ConstantsModalHeader from '../modal-header/ModalHeader.constants';
import * as Constants from './ModalContent.constants';
import { IModalContentProps } from './ModalContent.types';

interface IModalContentTokens {
    content: (props: IModalContentProps) => Fluent.IStackItemTokens;
}

interface IModalContentStyles {
    wrapper: (props: IModalContentProps, theme: Theme) => Fluent.IStackStyles;
}

export const ModalContentTokens: IModalContentTokens = {
    content: (props) => {
        const { borderLess = false, contentPadding = `${Constants.PADDING_Y}px ${Constants.PADDING_X}px` } = props;
        const padding = borderLess ? 0 : contentPadding;

        return {
            padding,
        };
    },
};

export const ModalContentStyles: IModalContentStyles = {
    wrapper: (props, theme) => {
        const { background, borderLess = false, fullScreen = false, showFooter } = props;

        if (borderLess) {
            return {
                root: [
                    {
                        'height': '100%',
                        'backgroundColor': background || theme.semanticColors.bodyBackground,
                        '-moz-border-radius': ModalConstants.BORDER_RADIUS,
                        'borderRadius': ModalConstants.BORDER_RADIUS,
                        'overflow': 'hidden',
                        'width': '100%',
                    },
                    fullScreen && {
                        height: `calc(100% - ${ConstantsModalHeader.HEIGHT_HEADERLESS}px)`,
                    },
                    (showFooter || fullScreen) && {
                        borderBottomLeftRadius: ConstantsModalHeader.MODAL_CONTENT_FULL_SCREEN_RADIUS_BOTTOM,
                        borderBottomRightRadius: ConstantsModalHeader.MODAL_CONTENT_FULL_SCREEN_RADIUS_BOTTOM,
                    },
                ],
            };
        }
        return {
            root: [
                {
                    backgroundColor: background,
                    height: `100%`,
                    overflowY: 'auto',
                    width: '100%',
                },
                getScrollbarStyles(theme),
            ],
        };
    },
};
