import { Resource } from 'i18next';
import { formCalloutLocale } from '../form-callout/locales/resources';
import { formPanelLocale } from '../form-panel/locales/resources';

export const formMoleculesLocales: Resource = {
    en: {
        formCallout: formCalloutLocale.en,
        formPanel: formPanelLocale.en,
    },
    fr: {
        formCallout: formCalloutLocale.fr,
        formPanel: formPanelLocale.fr,
    },
};
