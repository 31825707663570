import { BaseSlots, IColor, ThemeGenerator, createTheme, getColorFromString, isDark, themeRulesStandardCreator } from '@fluentui/react';
import { transformToMozzaikTheme } from './ThemeProvider.utils';

export const generateTheme = (primaryColor: string, textColor: string, backgroundColor: string) => {
    const themeRules = themeRulesStandardCreator();
    const colors = {
        pColor: getColorFromString(primaryColor) || ({ hex: '0078d4' } as IColor),
        tColor: getColorFromString(textColor) || ({ hex: '323130' } as IColor),
        bColor: getColorFromString(backgroundColor) || ({ hex: 'ffffff' } as IColor),
    };

    const currentIsDark = isDark(colors.bColor);

    ThemeGenerator.insureSlots(themeRules, currentIsDark);
    ThemeGenerator.setSlot(themeRules[BaseSlots[BaseSlots.primaryColor]], colors.pColor, currentIsDark, true, true);
    ThemeGenerator.setSlot(themeRules[BaseSlots[BaseSlots.foregroundColor]], colors.tColor, currentIsDark, true, true);
    ThemeGenerator.setSlot(themeRules[BaseSlots[BaseSlots.backgroundColor]], colors.bColor, currentIsDark, true, true);

    const themeAsJson = ThemeGenerator.getThemeAsJson(themeRules);

    const finalTheme = createTheme({
        ...{ palette: themeAsJson },
        isInverted: currentIsDark,
    });

    return transformToMozzaikTheme(finalTheme);
};
