import { IStackStyles, IStackTokens } from '@fluentui/react';
import { Theme } from '@theme';
import * as Constants from '../../Modal.constants';

export interface IModalFooterTokens {
    buttons: IStackTokens;
}
export const ModalFooterTokens: IModalFooterTokens = {
    buttons: {
        childrenGap: 12,
        padding: 16,
    },
};

interface IModalFooterStyles {
    container: (theme: Theme) => IStackStyles;
}
export const ModalFooterStyles: IModalFooterStyles = {
    container: (theme: Theme) => {
        return {
            root: [
                {
                    width: '100%',
                    borderTop: `1px solid ${theme.semanticColors.modalHeaderBorder}`,
                    backgroundColor: theme.semanticColors.bodyBackground,
                    borderRadius: Constants.BORDER_RADIUS,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                },
            ],
        };
    },
};
