export interface ISearchBoxProps {
    /** Debounce delay in milliseconds */
    debounceTime?: number;
    /** Whether the field is disabled. */
    disabled?: boolean;
    /** Callback for when the clear button is clicked */
    onClear?: () => void;
    /** Callback for when the input value changes. */
    onSearch: (searchText?: string) => void;
    /** The placeholder of the field. */
    placeholder?: string;
    /** The border radius of the field. */
    radius?: SearchBoxRadius;
    /** The content of the textfield */
    searchText?: string;
    /** The size of the field. */
    size?: SearchBoxSize;
    /** The type of the field. */
    type: SearchBoxType;
}

export enum SearchBoxRadius {
    Square = 'Square',
    Small = 'Small',
    Medium = 'Medium',
    Circle = 'Circle',
}

export enum SearchBoxSize {
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
}

export type SearchBoxType = 'primary' | 'secondary';
