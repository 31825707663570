import React, { useMemo, useState } from 'react';
import { Persona, PersonaShape, PersonaSize } from '@atoms';
import * as Fluent from '@fluentui/react';
import { useTheme } from '@theme';
import { PeoplePill } from '../people-pill';
import { IPeoplePillItem } from '../people-pill/PeoplePill.types';
import { PeoplePickerStyles as styles } from './PeoplePicker.styles';
import { IMember, IPeoplePickerProps } from './PeoplePicker.types';
import { convertToMemberFromPersona, convertToMembersFromPersonas, convertToPersonasFromMembers } from './PeoplePickerHelpers';

export const PeoplePicker: React.FC<IPeoplePickerProps> = (props) => {
    const {
        defaultSelectedMembers = [],
        itemLimit,
        loadingText,
        placeholder,
        onItemAdded,
        onItemRemoved,
        onSelectedItemsChange,
        noResultsFoundText,
        personaFilteredOnPromise,
        suggestionsContainerAriaLabel,
        peoplePickerVariation = 'normal',
        personaShape = 'round',
    } = props;

    const [selectedItems, setSelectedItems] = useState<IMember[]>(defaultSelectedMembers);
    const [inputMembers, setInputMembers] = useState<IMember[]>([]);

    const theme = useTheme();

    const suggestionProps: Fluent.IBasePickerSuggestionsProps = {
        noResultsFoundText,
        loadingText,
        suggestionsContainerAriaLabel,
    };

    const onFilterChanged = (iFilterText: string, iSelectedItems?: Fluent.IPersonaProps[]) => {
        const selectedUsers = iSelectedItems ? convertToMembersFromPersonas(iSelectedItems, inputMembers) : [];

        const promiseOnFilterChanged = async () =>
            personaFilteredOnPromise(iFilterText, selectedUsers).then((members) => {
                const membersWithoutDuplicates = removeSelectedMembers(members, iSelectedItems);
                setInputMembers(membersWithoutDuplicates);
                const personas = convertToPersonasFromMembers(membersWithoutDuplicates);
                return personas;
            });

        return promiseOnFilterChanged();
    };

    const onItemSelected = (item?: Fluent.IPersonaProps) => {
        if (!item) {
            return null;
        }
        const member = convertToMemberFromPersona(item, inputMembers);
        const updatedSelectedItems = [...selectedItems, member];

        setSelectedItems(updatedSelectedItems);

        onItemAdded && onItemAdded(member);
        onSelectedItemsChange(updatedSelectedItems);
        return item;
    };

    const onRemoveItem = (item?: IPeoplePillItem) => {
        const updatedSelectedItems = [...selectedItems].filter((selectedItem) => selectedItem.id !== item?.id);
        onSelectedItemsChange(updatedSelectedItems);
        setSelectedItems(updatedSelectedItems);

        onItemRemoved && onItemRemoved({ id: item?.id, displayName: item?.text, imageUrl: item?.imageUrl });
    };

    const onRenderSuggestionsItem = (personaProps: Fluent.IPersonaProps) => {
        const mozzaikImageUrl = personaProps.imageUrl ? personaProps.imageUrl : inputMembers.find((member) => member.id === personaProps.id)?.imageUrl;
        return (
            <Fluent.Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={{ padding: '6px 12px', maxWidth: '100%' }}>
                <Persona
                    size={PersonaSize.Size24}
                    displayName={personaProps.text}
                    imageUrl={mozzaikImageUrl}
                    shape={personaShape === 'round' ? PersonaShape.Circle : PersonaShape.Square}
                    dataTestId={`persona-${personaProps.text}`}
                />
            </Fluent.Stack>
        );
    };

    const onRenderItem = (itemProps: Fluent.IPickerItemProps<Fluent.IPersonaProps>) => {
        const { item, index } = itemProps;
        //replace imageUrl in item if not defined
        const peoplePillItem: IPeoplePillItem = {
            id: item.id,
            text: item.text,
            imageUrl: item.imageUrl ? item.imageUrl : selectedItems.find((member) => member.id === item.id)?.imageUrl,
        };
        return <PeoplePill key={item.id} index={index} item={peoplePillItem} type="secondary" onRemoveItem={onRemoveItem} />;
    };

    const removeSelectedMembers = (members: IMember[], selectedMembers?: IMember[]) => {
        if (!selectedMembers) {
            return members;
        }
        const selectedMembersId = selectedMembers.map((member) => member.id);
        return members.filter((member) => !selectedMembersId.includes(member.id));
    };

    const MzkPeoplePicker = peoplePickerVariation === 'list' ? Fluent.ListPeoplePicker : Fluent.NormalPeoplePicker;

    const selectedItemFluentPersonas: Fluent.IPersonaProps[] = useMemo(() => convertToPersonasFromMembers(selectedItems), [selectedItems]);

    return (
        <MzkPeoplePicker
            itemLimit={itemLimit}
            onResolveSuggestions={onFilterChanged}
            onEmptyResolveSuggestions={() => onFilterChanged('', selectedItemFluentPersonas)}
            inputProps={{ placeholder }}
            styles={(fluentProps) => styles.peoplePicker(fluentProps, props, theme)}
            pickerSuggestionsProps={suggestionProps}
            onRenderItem={onRenderItem}
            selectedItems={selectedItemFluentPersonas}
            onItemSelected={onItemSelected}
            pickerCalloutProps={{ styles: styles.suggestionsCallout(theme) }}
            onRenderSuggestionsItem={onRenderSuggestionsItem}
        />
    );
};
