import * as Fluent from '@fluentui/react';
import { Theme } from '@theme';
import * as Constants from './PanelHeader.constants';

interface IPanelHeaderTokens {
    header: Fluent.IStackTokens;
    title: Fluent.IStackTokens;
}

interface IPanelHeaderStyles {
    header: (withoutHeaderBorder: boolean, theme: Theme) => Fluent.IStackStyles;
    subHeader: (headerHorizontalFill?: boolean) => Fluent.IStackStyles;
}

export const PanelHeaderTokens: IPanelHeaderTokens = {
    header: { padding: `${Constants.PADDING_Y}px ${Constants.PADDING_RIGHT}px ${Constants.PADDING_Y}px ${Constants.PADDING_LEFT}px` },
    title: { childrenGap: Constants.HEADER_TITLE_GAP },
};

export const PanelHeaderStyles: IPanelHeaderStyles = {
    header: (withoutHeaderBorder, theme) => ({
        root: [
            {
                borderBottom: withoutHeaderBorder ? 'none' : `1px solid ${theme.semanticColors.panelHeaderBorder}`,
                height: Constants.HEIGHT,
            },
        ],
    }),
    subHeader: (headerHorizontalFill = false) => ({
        root: [
            {
                width: headerHorizontalFill ? '100%' : 'auto',
            },
        ],
    }),
};
