import { IStyle, ITheme } from '@fluentui/react';
import { getColorFromHex } from './getColorFromHex';

export const getScrollbarStyles = (theme: ITheme): IStyle => {
    return {
        selectors: {
            '::-webkit-scrollbar': {
                width: 8,
                height: 8,
            },
            '::-webkit-scrollbar-thumb': {
                backgroundColor: getColorFromHex(theme.palette.black, 20),
                borderRadius: 4,
            },
            '::-webkit-scrollbar-thumb:hover': {
                backgroundColor: getColorFromHex(theme.palette.black, 40),
                borderRadius: 4,
            },
        },
    };
};
