import { Elevation } from '../Elevation';
import { IAlphaValues } from './getElevation.types';

export const INITIAL_OFFSET_X = 0;
export const FIRST_INITIAL_OFFSET_Y = 0.3;
export const FIRST_INITIAL_BLUR_RADIUS = 0.9;
export const SECOND_INITIAL_OFFSET_Y = 1.6;
export const SECOND_INITIAL_BLUR_RADIUS = 3.6;

export const FIRST_DEFAULT_ALPHA_VALUE = 7;
export const SECOND_DEFAULT_ALPHA_VALUE = 5;

export const INTENSITY_TO_COLOR = new Map<Elevation, IAlphaValues>([
    [Elevation.Light, { firstAlphaValue: FIRST_DEFAULT_ALPHA_VALUE, secondAlphaValue: SECOND_DEFAULT_ALPHA_VALUE }],
    [Elevation.Medium, { firstAlphaValue: 12, secondAlphaValue: 10 }],
    [Elevation.Strong, { firstAlphaValue: 17, secondAlphaValue: 15 }],
]);
