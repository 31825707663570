import { ImageRatioType } from './Image.types';

export const RATIO_MAPPING = new Map<ImageRatioType, number>([
    ['1:1', 1],
    ['5:4', 1.25],
    ['4:3', 1.333],
    ['3:2', 1.5],
    ['16:10', 1.6],
    ['1.618:1', 1.618],
    ['16:9', 1.777],
    ['2:1', 2],
    ['21:9', 2.333],
    ['A4', 1.414],
    ['Letter', 1.294],
]);

/***** Values for tests *****/

export const IMAGE_MOCK = 'https://images.freeimages.com/images/large-previews/9e6/abstract-polygonal-low-poly-photos-texture-3-636190.jpg';

/* Default height of Image atom in tests */
export const DEFAULT_HEIGHT = 150;

/* Width values based on default height and image ratio */
export const CALCULATED_WIDTH_VALUES = new Map<ImageRatioType, number>([
    ['1:1', 150],
    ['5:4', 187.5],
    ['4:3', 199.95],
    ['3:2', 225],
    ['16:10', 240],
    ['1.618:1', 242.70000000000002],
    ['16:9', 266.55],
    ['2:1', 300],
    ['21:9', 349.95000000000005],
    ['A4', 212.1],
    ['Letter', 194.1],
]);

/* Default width of Image atom in tests */
export const DEFAULT_WIDTH = 250;

/* Height values based on default width and image ratio */
export const CALCULATED_HEIGHT_VALUES = new Map<ImageRatioType, number>([
    ['1:1', 250],
    ['5:4', 200],
    ['4:3', 187.54688672168044],
    ['3:2', 166.66666666666666],
    ['16:10', 156.25],
    ['1.618:1', 154.5117428924598],
    ['16:9', 140.68655036578502],
    ['2:1', 125],
    ['21:9', 107.15816545220746],
    ['A4', 176.80339462517682],
    ['Letter', 193.19938176197834],
]);
