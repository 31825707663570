import { Resource } from 'i18next';
import { formModalLocale } from '../form-modal/locales/resources';

export const formOrganismsLocales: Resource = {
    en: {
        formModal: formModalLocale.en,
    },
    fr: {
        formModal: formModalLocale.fr,
    },
};
