import { Resource } from 'i18next';
import { imagePickerLocales } from '../image-picker/locales/resources';
import { messageBarLocales } from '../message-bar/locales/resources';
import { personasGroupLocales } from '../personas-group/locales/resources';

export const moleculeLocales: Resource = {
    en: {
        imagePicker: imagePickerLocales.en,
        messageBar: messageBarLocales.en,
        personasGroup: personasGroupLocales.en,
    },
    fr: {
        imagePicker: imagePickerLocales.fr,
        messageBar: messageBarLocales.fr,
        personasGroup: personasGroupLocales.fr,
    },
};
