import * as Fluent from '@fluentui/react';
import { Theme } from '@theme';
import { FontSizes } from '@tokens';
import * as Constants from './DetailsList.constants';

interface IDetailsListStyles {
    header: (theme: Theme) => Partial<Fluent.IDetailsHeaderStyles>;
    columns: Partial<Fluent.IDetailsColumnStyles>;
    rows: (theme: Theme) => Partial<Fluent.IDetailsRowStyles>;
}

export const ListStyles: IDetailsListStyles = {
    header: (theme) => ({
        root: {
            height: Constants.HEADER_HEIGHT,
            padding: 0,
            borderColor: theme.semanticColors.listHeaderBorder,
        },
    }),
    columns: {
        cellName: {
            fontSize: FontSizes.Size12,
        },
    },
    rows: (theme) => ({
        root: {
            height: Constants.ROW_HEIGHT,
            transition: 'background-color 250ms ease-out',
            selectors: {
                '&:hover': {
                    backgroundColor: theme.semanticColors.listRowHovered,
                    borderRadius: Constants.ROW_HOVERED_RADIUS,
                },
            },
        },
        cell: {
            display: 'flex',
            alignItems: 'center',
        },
    }),
};
